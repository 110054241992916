<template>
  <v-app id="app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
};
</script>
<style scoped>
@font-face {
  font-family: "SFThonburi";
  src: url("./assets/fonts/SFThonburi-Bold.ttf");
}
#app {
  /* background: url("./assets/hand-painted.jpg") no-repeat center center fixed !important; */
  background-size: contain, cover;
  width: 100%;
  min-height: 100%;
  padding: 10px;
  background-color: #e8f7ff;
  box-sizing: border-box;
  font-family: "SFThonburi";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;  
}
img{
   padding: 10px;
}
</style>
