import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/listfarm",
    name: "listfarm",
    component: () => import("../views/Listfarm.vue"),
    meta: {
      authorize: [],
    },
  },
  {
    path: "/farmdetail",
    name: "farmdetail",
    component: () => import("../views/Farmdetail.vue"),
    meta: {
      authorize: [],
    },
  },
  {
    path: "/farm",
    name: "farm",
    component: () => import("../views/Farm.vue"),
    meta: {
      authorize: [],
    },
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("../views/Setting.vue"),
    meta: {
      authorize: [],
    },
  },
  {
    path: "/status",
    name: "status",
    component: () => import("../views/Status.vue"),
    meta: {
      authorize: [],
    },
  }, 
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  const currentUser = localStorage.getItem("jwt"); 
  if (authorize) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({
        path: "/",
        query: {
          returnUrl: to.path,
        },
      });
    }
    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.role)) {
      // role not authorised so redirect to home page
      return next({
        path: "/",
      });
    }
  }

  next();
});
export default router;
