import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify"; // path to vuetify export
import axios from "axios";
import VueAxios from "vue-axios";
import VueSession from "vue-session";
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css"; 
Vue.use(VueSession);
Vue.use(VueSweetalert2);
Vue.prototype.moment = moment;
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY");
  }
});  
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
